import { Howler } from "howler";
import { lazy, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorScreen } from "./components/ErrorScreen";
import { FullPageLoader } from "./components/FullPageLoader";
import { ScreenWrapper } from "./components/ScreenWrapper";
import { EventStoreProvider } from "./providers/eventStore/EventStoreProvider";
import { UserProvider } from "./providers/user/UserProvider";
import { Router, Screen } from "./router";
import { WelcomeScreen } from "./screens/WelcomeScreen";

import "@ancestors/components/global.css";

Howler.autoSuspend = false;

const Echo = lazy(() => import("./Echo"));
const Slides = lazy(() => import("./Slides"));

export default function App() {
  const isEcho = window.location.pathname.startsWith("/echo");
  const isSlide = window.location.pathname.startsWith("/slides");

  if (isEcho) {
    return (
      <AppWrapper>
        <Echo />
      </AppWrapper>
    );
  }

  if (isSlide) {
    return (
      <EventStoreProvider>
        <Slides />
      </EventStoreProvider>
    );
  }

  return (
    <AppWrapper>
      <Router>
        <Screen name="Welcome" component={WelcomeScreen} />
        <Screen
          name="Registration"
          component={lazy(() => import("./screens/RegistrationScreen"))}
        />
        <Screen name="Selfie" component={lazy(() => import("./screens/selfie/SelfieScreen"))} />
        <Screen
          name="Onboarding"
          component={lazy(() => import("./screens/onboarding/OnboardingScreen"))}
        />
        <Screen
          name="Interface"
          component={lazy(() => import("./screens/interface/InterfaceScreen"))}
        />
      </Router>
    </AppWrapper>
  );
}

function AppWrapper({ children }: { children: React.ReactNode }) {
  return (
    <EventStoreProvider>
      <UserProvider>
        <ScreenWrapper>
          <ErrorBoundary FallbackComponent={ErrorScreen}>
            <Suspense fallback={<FullPageLoader />}>{children}</Suspense>
          </ErrorBoundary>
        </ScreenWrapper>
      </UserProvider>
    </EventStoreProvider>
  );
}
