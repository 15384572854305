import { createReference } from "../lib/firebase";
import { FirebaseStore } from "../lib/FirebaseStore";

const FIREBASE_KEY = "slide";

export interface SlideData {
  current: string;
}

export class SlideStore extends FirebaseStore<SlideData> {
  constructor({ eventPrefix }: { eventPrefix: string }) {
    super(createReference(eventPrefix, FIREBASE_KEY));
  }
}
